.new-background-box {
  /* margin-top: 100px;
    margin-bottom: 50px; */
  width: 100%;
  padding: 20px 40px;
  background-color: rgba(27, 27, 27, 0.5);
  border-radius: 20px;
  /* padding-bottom: 10px; */
}

.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

.test {
  position: absolute;
  top: 20%;
  right: 1%;
}

.test:hover {
  color: #535050;
  cursor: pointer;
}

.p {
  margin: 0;
}